<template>
  <div id="centreRight2">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span style="color: #5cd9e8">
          <icon name="chart-line"></icon>
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2 fs26">溶解氧在线监测 - 浓度</span>
          <dv-decoration-6
            style="width: 3.6rem; height: 0.25rem; position: relative; top: -0.0375rem"
          />
          <span class="fs-xl text mx-2 text-right fs26">当前指数： {{ value }}mg/L</span>
        </div>
      </div>
      <div class=" " style="width: 100%">
        <bottomRightChart2 :cdata="cdata"/>
      </div>
    </div>
  </div>
</template>

<script>
import bottomRightChart2 from "@/components/echart/bottom/bottomRightChart2";
export default {
  props: ["value","cdata"],
  data() {
    return {};
  },
  components: {
    bottomRightChart2,
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss">
#centreRight2 {
  padding: 0.2rem;
  height: 3.65rem;
  min-width: 5.8rem;
  border-radius: 0.0625rem;
  .bg-color-black {
    height:  3.25rem;
    border-radius: 0.125rem;
  }
  .text {
    color: #c3cbde;
  }
  .chart-box {
    margin-top: 0.2rem;
    width: 2.125rem;
    height: 2.125rem;
    .active-ring-name {
      padding-top: 0.125rem;
    }
  }
}
</style>
