<template>
  <div id="index">
    <dv-full-screen-container class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <div class="d-flex jc-center" style="padding-top: 10px">
          <dv-decoration-10 style="width: 45%; height: 0.0625rem" />
          <div class="d-flex jc-center">
            <dv-decoration-8
              :color="['#568aea', '#000000']"
              style="width: 2.5rem; height: 0.625rem"
            />
            <div class="title">
              <span class="title-text">智慧河道数据可视化展示屏</span>
              <dv-decoration-6
                class="title-bototm"
                :reverse="true"
                :color="['#50e3c2', '#67a1e5']"
                style="width: 7.2rem; height: 0.1rem"
              />
            </div>
            <dv-decoration-8
              :reverse="true"
              :color="['#568aea', '#000000']"
              style="width: 2.5rem; height: 0.625rem"
            />
          </div>
          <dv-decoration-10
            style="width: 45%; height: 0.0625rem; transform: rotateY(180deg)"
          />
        </div>

        <!-- 第二行 -->
        <div class="d-flex jc-between px-2">
          <div class="d-flex" style="width: 40%">
            <div
              class="react-right ml-4"
              style="width: 6.25rem; text-align: left; background-color: #0f1325"
            >
              <span class="react-before"></span>
              <span class="text"
                >{{ dateYear }} {{ dateWeek }} {{ dateDay }} 沙溪古镇</span
              >
            </div>
          </div>
          <div style="width: 35%" class="d-flex">
            <div class="react-right" style="width: 2rem"></div>
            <div class="react-right" style="width: 6.3rem; background-color: #0f1325">
              <span class="react-after"></span>
              <span class="text">{{ weather }}</span>
            </div>
          </div>
        </div>

        <div class="body-box">
          <div class="bototm-box" style="position: relative">
            <swiper
              class="swiper swiper-container"
              :options="swiperOptions"
              ref="mySwipers"
            >
              <swiper-slide>
                <div style="margin-top: 25x">
                  <dv-border-box-13>
                    <bottomLeft />
                  </dv-border-box-13>
                </div>
              </swiper-slide>
              <swiper-slide>
                <bottomRight />
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </div>
    </dv-full-screen-container>
  </div>
</template>

<script>
import { formatTime } from "../utils/index.js";
import bottomLeft from "./bottomLeft";
import bottomRight from "./bottomRight";
import { swiper,swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
  data() {
    return {
      loading: true,
      isOne: true,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
      weather: null,
      swiperOptions: {
        effect: "slide",
        autoplay: {
          delay: 8000, //8
          stopOnLastSlide: false,
          disableOnInteraction: true,
        },
        observer: true,
        observeParents: true,
        //滑动速度
        speed: 1,
      },
    };
  },
  components: {
    bottomLeft,
    bottomRight,
    swiper,
    swiperSlide,
  },
  mounted() {
    this.timeFn();
    this.cancelLoading();
    this.getWeather();
  },
  computed: {
    swipers() {
      return this.$refs.mySwipers.swipers;
    },
  },
  methods: {
    getWeather() {
      console.log("天气");
      this.$axios({
        method: "get",
        url:
          "https://www.tianqiapi.com/api?version=v61&appid=98923686&appsecret=BmaBgCV2",
      }).then((response) => {
        console.log("sss", response.data);
        console.log("最高温", response.data.tem1);
        console.log("最低温", response.data.tem2);
        console.log("天气情况", response.data.wea + response.data.win);
        // this.weather =
        //   "温度：" +
        //   response.data.tem2 +
        //   "~" +
        //   response.data.tem1 +
        //   "°C PM2.5：" +
        //   response.data.aqi.pm25 +
        //   "," +
        //   response.data.aqi.pm25_desc;
      });
    },
    timeFn() {
      setInterval(() => {
        this.dateDay = formatTime(new Date(), "HH: mm: ss");
        this.dateYear = formatTime(new Date(), "yyyy-MM-dd");
        this.dateWeek = this.weekday[new Date().getDay()];
      }, 1000);
    },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
  },
};
</script>

<style lang="scss">
@import "../assets/scss/index.scss";
body {
  opacity: 0;
  transition: opacity 0.2s;
}
</style>
