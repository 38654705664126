<template>
  <div>
    <div class="topclass2" v-for="item in leftList" :key="item.device_name">
      <div class="szbg2">
        <div class="titles">{{ item.device_name }}</div>
        <div class="dengji">
          {{ item.current_value }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      current: 0,
      leftList: [],
      thisWeek: [],
      endDate: "",
      startDate: "",
      datasa: 5,
    };
  },

  async mounted() {
    this.getTime();
    this.getRecordReport();
    await this.getDeviceMap();
    setInterval(() => {
      this.getDeviceMap();
    }, 30000);
  },

  methods: {
    async getDeviceMap() {
      let res = await this.$Http.getDeviceMap();
       console.log("ssss3", res);

      this.leftList = res.module;
      //mg/L
      this.leftList.forEach((e) => {
        var asd = e.current_value;
        if (
          e.device_name == "CODMn检测仪" ||
          e.device_name == "溶解氧检测仪" ||
          e.device_name == "氨氮检测仪" ||
          e.device_name == "总磷检测仪"
        ) {
          asd = parseFloat(asd).toFixed(3);
          e.current_value = asd + "mg/L";
          //console.log(e.current_value);
        }
        if (e.device_name == "浊度检测仪") {
          asd = parseFloat(asd).toFixed(3);
          e.current_value = asd + "NTU";
        }
      });
      sessionStorage.setItem("leftList", JSON.stringify(this.leftList));
    },
    async getRecordReport() {
      //console.log("时间1", this.endDate);
      //console.log("时间2", this.startDate);
      await this.$Http.getRecordReport({
        endDate: this.endDate,
        startDate: this.startDate,
        // endDate: "2020-11-15",
        // startDate: "2020-11-1",
      });

      //console.log("alls", res);
    },
    getTime() {
      var myDate = new Date(); // 获取今天日期
      myDate.setDate(myDate.getDate() - 6);
      var dateArray = [],
        flag = 1;
      for (var i = 0; i < 7; i++) {
        let year = myDate.getFullYear(); //年
        let month = myDate.getMonth() + 1; //月
        let day = myDate.getDate(); //日;

        dateArray.push(year + "-" + month + "-" + day);

        myDate.setDate(myDate.getDate() + flag);
      }
      this.thisWeek = dateArray; //本周
      // console.log(this.thisWeek);
      this.startDate = this.thisWeek[0];
      this.endDate = this.thisWeek[6];
    },
  },
};
</script>

