<template>
  <div id="bottomRight" style="z-index:999">
    <div class="bg-color-black">
      <div class="content-box">
        <dv-border-box-13> <centerLeft1 :value="value" /> </dv-border-box-13>
      </div>

      <div class="content-box m-l-30">
        <dv-border-box-13>
          <BottomRight1 :value="value1" :cdata="cdata" />
        </dv-border-box-13>
      </div>

      <div class="content-box m-t-10">
        <dv-border-box-13>
          <BottomRight2 :value="value2" :cdata="cdata1" />
        </dv-border-box-13>
      </div>

      <div class="content-box m-t-10 m-l-30">
        <dv-border-box-13>
          <BottomRight3 :value="value3" :cdata="cdata2" />
        </dv-border-box-13>
      </div>

      <div class="content-box m-t-10">
        <dv-border-box-13>
          <BottomRight4 :value="value4" :cdata="cdata3" />
        </dv-border-box-13>
      </div>

      <div class="content-box m-t-10 m-l-30">
        <dv-border-box-13>
          <BottomRight5 :value="value5" :cdata="cdata4" />
        </dv-border-box-13>
      </div>
    </div>
  </div>
</template>

<script>
import centerLeft1 from "./centerLeft1";
import BottomRight1 from "./bottomRigh1";
import BottomRight2 from "./bottomRigh2";
import BottomRight3 from "./bottomRigh3";
import BottomRight4 from "./bottomRigh4";
import BottomRight5 from "./bottomRigh5";

export default {
  data() {
    return {
      leftList: [],
      value: 0,
      value1: 1,
      value2: 2,
      value3: 3,
      value4: 4,
      value5: 5,
      cdata: {
        year: null,
        weekCategory: [],
        radarData: [],
        radarDataAvg: [],
        maxData: 10000,
        weekMaxData: [],
        weekLineData: [],
      },
      cdata1: {
        year: null,
        weekCategory: [],
        radarData: [],
        radarDataAvg: [],
        maxData: 10000,
        weekMaxData: [],
        weekLineData: [],
      },
      cdata2: {
        year: null,
        weekCategory: [],
        radarData: [],
        radarDataAvg: [],
        maxData: 10000,
        weekMaxData: [],
        weekLineData: [],
      },
      cdata3: {
        year: null,
        weekCategory: [],
        radarData: [],
        radarDataAvg: [],
        maxData: 10000,
        weekMaxData: [],
        weekLineData: [],
      },
      cdata4: {
        year: null,
        weekCategory: [],
        radarData: [],
        radarDataAvg: [],
        maxData: 10000,
        weekMaxData: [],
        weekLineData: [],
      },
      ListWeek: [],
      rightList: [],
      rightList1: [],
      rightList2: [],
      rightList3: [],
      rightList4: [],
      endDate: "",
      startDate: "",
    };
  },
  components: {
    centerLeft1,
    BottomRight1,
    BottomRight2,
    BottomRight3,
    BottomRight4,
    BottomRight5,
  },
  mounted() {
    this.getTimeWeek();
    this.getTime();
    this.getRecordReport();
    this.getDeviceMap();
    setInterval(() => {
      this.getTimeWeek();
      this.getTime();
      this.getRecordReport();
      this.getDeviceMap();
    }, 30000);
  },
  methods: {
    async getDeviceMap() {
      let res = await this.$Http.getDeviceMap();
      console.log("11111", res);
      this.leftList = res.module;
      //mg/L
      this.leftList.forEach((e) => {
        if (e.device_name == "河道水质评分") {
          this.value = e.current_value;
        }
        if (e.device_name == "CODMn检测仪") {
          var asd1 = parseFloat(e.current_value).toFixed(3);
          this.value1 = asd1;
        }
        if (e.device_name == "溶解氧检测仪") {
          var asd2 = parseFloat(e.current_value).toFixed(3);
          this.value2 = asd2;
        }
        if (e.device_name == "氨氮检测仪") {
          var asd3 = parseFloat(e.current_value).toFixed(3);
          this.value3 = asd3;
        }
        if (e.device_name == "浊度检测仪") {
          var asd4 = parseFloat(e.current_value).toFixed(3);
          this.value4 = asd4;
        }
        if (e.device_name == "总磷检测仪") {
          var asd5 = parseFloat(e.current_value).toFixed(3);
          this.value5 = asd5;
          console.log("总磷检测仪", asd5);
        }

        var asd = e.current_value;
        if (
          e.device_name == "CODMn检测仪" ||
          e.device_name == "溶解氧检测仪" ||
          e.device_name == "氨氮检测仪" ||
          e.device_name == "总磷检测仪"
        ) {
          asd = parseFloat(asd).toFixed(3);
          e.current_value = asd + "mg/L";
          console.log(e.current_value);
        }
        if (e.device_name == "浊度检测仪") {
          asd = parseFloat(asd).toFixed(3);
          e.current_value = asd + "NTU";
        }
      });
    },
    async getRecordReport() {
      var myDate = new Date(); // 获取今天日期
      this.cdata.year = myDate.getFullYear();
      this.cdata.weekCategory = this.ListWeek;

      this.cdata1.year = myDate.getFullYear();
      this.cdata1.weekCategory = this.ListWeek;

      this.cdata2.year = myDate.getFullYear();
      this.cdata2.weekCategory = this.ListWeek;

      this.cdata3.year = myDate.getFullYear();
      this.cdata3.weekCategory = this.ListWeek;

      this.cdata4.year = myDate.getFullYear();
      this.cdata4.weekCategory = this.ListWeek;

      let res = await this.$Http.getRecordReport({
        endDate: this.endDate,
        startDate: this.startDate,
      });

      console.log("rightList1", res);

      this.rightList = res.module.list_CODMn;
      this.rightList1 = res.module.list_rjy;
      this.rightList2 = res.module.list_ad;
      this.rightList3 = res.module.list_zd;
      this.rightList4 = res.module.list_zl;

      var weekLineData = [],
        weekLineData1 = [],
        weekLineData2 = [],
        weekLineData3 = [],
        weekLineData4 = [];

      console.log("rightList11111111111", this.rightList);
      var listsmax = [];
      for (let i = 0; i < this.rightList.length; i++) {
        var asd = this.rightList[i].avg_value;
        weekLineData.push(asd.toFixed(2));
        listsmax.push(asd.toFixed(2));
      }

      var listsmax1 = [];
      for (let i = 0; i < this.rightList1.length; i++) {
        var asd1 = this.rightList1[i].avg_value;
        weekLineData1.push(asd1.toFixed(2));
        listsmax1.push(asd1.toFixed(2));
      }
      var listsmax2 = [];
      for (let i = 0; i < this.rightList2.length; i++) {
        var asd2 = this.rightList2[i].avg_value;
        weekLineData2.push(asd2.toFixed(2));
        listsmax2.push(asd2.toFixed(2));
      }
      var listsmax3 = [];
      for (let i = 0; i < this.rightList3.length; i++) {
        var asd3 = this.rightList3[i].avg_value;
        weekLineData3.push(asd3.toFixed(2));
        listsmax3.push(asd3.toFixed(2));
      }
      var listsmax4 = [];
      for (let i = 0; i < this.rightList4.length; i++) {
        var asd4 = this.rightList4[i].avg_value;
        weekLineData4.push(asd4.toFixed(2));
        listsmax4.push(asd4.toFixed(2));
      }

      listsmax.sort(function (a, b) {
        return a - b;
      });

      var max = listsmax[listsmax.length - 1];

      listsmax1.sort(function (a, b) {
        return a - b;
      });

      var max1 = listsmax1[listsmax1.length - 1];

      listsmax2.sort(function (a, b) {
        return a - b;
      });

      var max2 = listsmax2[listsmax2.length - 1];

      listsmax3.sort(function (a, b) {
        return a - b;
      });

      var max3 = listsmax3[listsmax3.length - 1];

      listsmax4.sort(function (a, b) {
        return a - b;
      });

      var max4 = listsmax4[listsmax4.length - 1];

      this.cdata.weekLineData = weekLineData;
      this.cdata.maxData = Number(max * 1.5).toFixed(2);
      this.cdata.radarDataAvg = "";

      this.cdata1.weekLineData = weekLineData1;
      this.cdata1.maxData = Number(max1 * 1.5).toFixed(2);
      this.cdata1.radarDataAvg = "";

      this.cdata2.weekLineData = weekLineData2;
      this.cdata2.maxData = Number(max2 * 1.5).toFixed(2);
      this.cdata2.radarDataAvg = "";

      this.cdata3.weekLineData = weekLineData3;
      this.cdata3.maxData = Number(max3 * 1.5).toFixed(2);
      this.cdata3.radarDataAvg = "";

      this.cdata4.weekLineData = weekLineData4;
      this.cdata4.maxData = Number(max4 * 1.5).toFixed(2);
      this.cdata4.radarDataAvg = "";

      console.log("cdata1", this.cdata);
    },
    getTime() {
      var myDate = new Date(); // 获取今天日期
      myDate.setDate(myDate.getDate() - 6);
      var dateArray = [],
        flag = 1;
      for (var i = 0; i < 7; i++) {
        let year = myDate.getFullYear(); //年
        let month = myDate.getMonth() + 1; //月
        let day = myDate.getDate(); //日;

        dateArray.push(year + "-" + month + "-" + day);

        myDate.setDate(myDate.getDate() + flag);
      }
      this.thisWeek = dateArray; //本周
      console.log(this.thisWeek);
      this.startDate = this.thisWeek[0];
      this.endDate = this.thisWeek[6];
    },
    getTimeWeek() {
      var myDate = new Date(); // 获取今天日期
      myDate.setDate(myDate.getDate() - 6);
      var dateArray = [],
        flag = 1;
      for (var i = 0; i < 7; i++) {
        let month = myDate.getMonth() + 1; //月
        let day = myDate.getDate(); //日;

        dateArray.push(month + "/" + day);

        myDate.setDate(myDate.getDate() + flag);
      }
      this.ListWeek = dateArray; //本周
      console.log(this.ListWeek);
    },
  },
};
</script>

<style lang="scss">
#bottomRight {
  padding: 0 0.2rem 0 0.1rem;
  height: 100%;
  min-width: 3.75rem;
  border-radius: 0.0625rem;
  .content-box {
    float: left;
    width: 49.1%;
  }

  .bg-color-black {
    border-radius: 0.125rem;
  }
  .text {
    color: #c3cbde;
  } //下滑线动态
  .decoration2 {
    position: absolute;
    right: 0.125rem;
  }
  .chart-box {
    margin-top: 0.2rem;
    width: 2.125rem;
    height: 2.125rem;
    .active-ring-name {
      padding-top: 0.125rem;
    }
  }
}
</style>
