<template>
  <div id="centreLeftHd">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span style="color: #5cd9e8;font-size:20px">
          <icon name="chart-pie" ></icon>
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2 fs26">上月河道水质评分</span>
          <dv-decoration-3
            style="width: 5.6rem; height: 0.25rem; position: relative; top: -0.0375rem"
          />
          <span class="fs-xl text mx-2 text-right fs26">当前评分: {{ value }}</span>
        </div>
      </div>
      <div class="d-flex jc-center">
        <CentreLeft1Chart />
      </div>
      <!-- 4个主要的数据 -->
    </div>
  </div>
</template>

<script>
import CentreLeft1Chart from "@/components/echart/centerLeft/centerLeft1Chart";
export default {
  props: ["value"],
  data() {
    return {};
  },
  components: {
    CentreLeft1Chart,
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss">
#centreLeftHd {
  padding: 0.2rem;
  height: 3.65rem;
  min-width: 5.8rem;
  border-radius: 0.0625rem;
  .bg-color-black {
    height: 3.3rem;
    border-radius: 0.125rem;
  }
  .text {
    color: #c3cbde;
  }
  .chart-box {
    margin-top: 0.2rem;
    width: 2.125rem;
    height: 2.125rem;
    .active-ring-name {
      padding-top: 0.125rem;
    }
  }

  .bottom-data {
    .item-box {
      float: right;
      position: relative;
      width: 50%;
      color: #d3d6dd;
      // 金币
      .coin {
        position: absolute;
        left: 0.1rem;
        top: 0.2125rem;
        font-size: 0.25rem;
        color: #ffc107;
      }
      .colorYellow {
        color: yellowgreen;
      }
    }
  }
}
</style>
