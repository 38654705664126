<template>
  <div id="bottomLeft">
    <div class="bg-color-black">
      <div style="float: left; width: 30%; padding: 1%">
        <bottomLeft1 />
      </div>

      <div class="topclass3" style="margin: 14px auto; text-align: center">
        <iframe
          src="http://localhost/"
          style="width: 100%; height: 417px"
          allowTransparency="true"
          frameborder="no"
          border="0"
          marginwidth="0"
          marginheight="0"
          scrolling="no"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import bottomLeft1 from "./bottomLeft1";
export default {
  data() {
    return {
      current: 0,
      leftList: [],
      thisWeek: [],
      endDate: "",
      startDate: "",
      datasa: 5,
      token: "",
      src1: "",
      src2: "",
      src3: "",
      src4: "",
    };
  },
  components: {
    bottomLeft1,
  },
  created() {
    //this.getVideoToken();
    // this.videoInitPlugin();
  },
  mounted() {
    console.log("sssssss");
    console.log("shijie", this.src1);
  },

  methods: {},
};
</script>

<style lang="scss">
body {
  opacity: 0;
  transition: opacity 0.2s;
}
#bottomLeft {
  padding: 0.2rem 0.2rem;
  height: 11.2rem;
  min-width: 3.75rem;
  border-radius: 0.0625rem;
  .plugin {
    width: 900px;
    height: 560px;
    margin: 0 auto;
  }
  .bg-color-black {
    height: 100%;
    border-radius: 0.125rem;
  }
  .fillbg {
    background-color: rgba(0, 0, 0, 0.6);
    bottom: 0;
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 1100;
    display: none;
  }
  .fillbg-active {
    opacity: 1;
    display: block;
  }

  .topclass2 {
    float: left;
    width: 50%;
  }
  .topclass3 {
    float: left;
    width: 70%;
    li {
      float: left;
      width: 48%;
      margin: 10px;
      img {
        max-height: 400px;
      }
    }
  }
  .bottomclass1 {
    float: left;
    width: 48%;
    margin: 1%;
    text-align: center;
    margin-bottom: 0;
    .cbgs {
      background: url(../assets/bgs.png);
      width: 58px;
      height: 53px;
      position: absolute;
      bottom: 10px;
      right: 10px;
    }
  }
  .text {
    color: #c3cbde;
  }

  .szbg2 {
    background: url("../assets/szbg2.png") no-repeat;
    background-size: 100%;
    text-align: center;
    padding-top: 15%;
    padding-bottom: 10%;
    margin-right: 1%;
    margin-bottom: 15%;
    .titles {
      font-size: 25px;
      font-weight: 400;
      color: #ffffff;
      text-shadow: 0px 6px 14px #000000;
      height: 20px;
    }
    .dengji {
      font-size: 30px;
      font-weight: bold;
      //color: #ffffff;
      //text-shadow: 0px 6px 14px #000000;
      //background: -ms-linear-gradient(top, #73dafc, #2dcbff); /* IE 10 */
      // background: -moz-linear-gradient(top, #73dafc, #2dcbff); /*火狐*/
      // background: linear-gradient(to top, #73dafc 0%, #2dcbff 100%);
      //background: -ms-linear-gradient(left,#daa23e,#ad7f27);

      background-image: -webkit-gradient(
        linear,
        0 0,
        0 bottom,
        from(#73dafc),
        to(#2dcbff)
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      display: inline-block;
      color: #73dafc;
      height: 45px;
      line-height: 45px;
    }
  }
  .chart-box {
    margin-top: 0.2rem;
    width: 2.125rem;
    height: 2.125rem;
    .active-ring-name {
      padding-top: 0.125rem;
    }
  }
}
</style>
