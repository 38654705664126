<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue';
export default {
  data () {
    return {
      cdata: {
        seriesData: [
          { value: 10, name: "44以下" },
          { value: 5, name: "45~59" },
          { value: 15, name: "60~74" },
          { value: 25, name: "90~100" },
          { value: 20, name: "75~89" },
        ]
      }
    }
  },
  components: {
    Chart,
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>

</style>